$green: #004d26;

.card-location-wrapper {
    padding-right: 0;
}

.MuiCardContent-root.card-location-content {
    padding: 0.5em;
    padding-bottom: 0;

    .img-location {
        width: 100%;
        border-radius: 1em;
    }

    .column-text p {
        font-size: 1em;
    }

    .first-text-column p {
        font-weight: bold;
        font-size: 1.2em;
    }
}
