
$orange: #f65812;
$green: #004D26;

.add-btn-content {
    float: right;
    margin-top: -2.7rem;
}

.add-text {
    color: $orange;
    font-size: 0.8em;
    font-weight: bold;
    padding-top: 0.8em;
}

.add-btn {
    color: $orange !important;
    transform: scale(1.5);
    margin-right: -20px !important;
}

.card-wrapper-new-form {
    padding: 0.5em 1.2em;

    .cancel-btn {
        margin-left: 1em;
    }

    .new-card-title {
        color: green;
        font-size: 1.3em;
        margin-bottom: 0.5em;
        font-weight: 500;
    }
}

.btn-clean {
    background: transparent;
    border: 0;
}
