$mainGreen: #154D25;
.course-cards {
    .course-img-container {
        height: 8em;

        .course-img {
            height: 100%;
        }
    }

    .type-custom {
        .course-img-container {
            background-color: #eee;
            height: 5em;
            width: 5em;
            padding: 1.2em;
            border-radius: 50%;
            border: solid 1px $mainGreen;
            text-align: center;
        }

        p.course-subtitle {
            margin-bottom: 2em;
        }
    }

    h2 {
        margin-bottom: 0;
    }

    .card-title {
        margin: 0.5em 0;
        color: $mainGreen;
        text-transform: uppercase;
        padding: 0;

        span {
            font-size: 1.9em;
            font-weight: 500;
        }
    }

    div.MuiCardContent-root {
        padding: 0!important;
    }

    p.course-subtitle {
        font-weight: 500;
        margin-bottom: 0.5em;
    }

    p.course-description {
        font-size: 1em;
    }

    .course-card {
        padding: 2em;
        margin: 1em;
        border: solid 1px $mainGreen;
        border-radius: 1em !important;
        height: 93%;
    }
}

.course-container {
    padding-left: 2rem;
}

.course-next {
    font-weight: 500;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 25%;
    flex-basis: 25%;
}

button.course-next-icon {
    background-color: black;
    color: white;
    padding: 0.2rem;
    transform: scale(1.5);
    margin-left: 1rem;

    &:hover {
        background-color: gray;
    }
}
