$green: #004d26;

.card-header {
    margin-top: -5em;
    margin-bottom: 0;
    font-weight: 500;
}

.font-color-green {
    color: $green;
    font-size: 1.5em;
    font-weight: 500;
}

.border-color-green {
    color: $green;
}

.trigger-container {
    border: 0;
    background: transparent;

    &.active-false {
        opacity: .5;
    }
}
