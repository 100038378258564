$orange: #ff580f;
$green: #004d26;

div {
    button {
        &.orange {
            color: $orange;
        }

        &.black {
            color: black;
        }

        &.gray {
            color: gray;
        }

        &.green {
            color: $green;
        }
    }
} 
