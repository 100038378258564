$green: #004d26;

.card-header {
    margin-top: 0.4em;
    margin-bottom: 0;
    font-weight: 500;

    h2 {
        color: $green;
        font-size: 1.3em;
        font-weight: 500;
    }
}

.border-color-green {
    color: $green;
}

.trigger-container {
    border: 0;
    background: transparent;

    &.active-false {
        opacity: .5;
    }
}

.welcome-text {
    color: $green;
    font-size: 1rem;
}

.welcome-username{
    color: $green;
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: 1px;
}