#SignUp > div > div:nth-child(2) > div {
  width: 85%;
}

#SignUp > div > div:nth-child(3) > div {
  width: 85%;
}

#SignUp > div > div:nth-child(4) > div {
  width: 85%;
}

#SignUp > div > div:nth-child(5) > div {
  width: 85%;
}