  .main {
    display: flex;
    padding: 48px;
    flex: 1 0 auto;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    text-align: left;
  }
