.right{
  align-self: flex-end;
}

.left{
  align-self: flex-start;
}

.center{
  align-self: center;
}

svg.custom-icon{
  font-size: 2.5rem;
  color: black;
}

span.custom-badge{
  margin-top: 0.5em;
}
