  .root {
    width: '100%';
    min-width: 600px;
    font-size:1.5rem;
    overflow: auto;

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }

  .header,
  .footer {
    height: 100px;
    line-height: 100px;
    text-align: center;
    background-color: rgba(0,0,0,.8);
    color: #fff;
    overflow: hidden;
  }

  .main {
    display:flex;
    padding: 0;
    min-height: calc(100vh - 165px);
    flex-direction: column;
    justify-content: flex-start;
  }

  .logo {
    height: 100px;
    padding:2px;
  }

  // .image {
  //   height: 200px;
  //   padding: 2px;
  // }

  .code {
    margin: 16px;
    padding: 32px 64px;
    border-radius: 8px;
    background-color: #292d3e;
    color: #bfc7d5;
    font-family: "Roboto Mono";
    text-align: left;
    word-wrap: none;
    animation: pulse 5s infinite;
  }

@keyframes pulse {
  0% { box-shadow: 0 0 4px 4px transparent; }
  30% { box-shadow: 0 0 4px 4px #f00; }
  40% { box-shadow: 0 0 4px 4px #f00; }
  90% { box-shadow: 0 0 4px 4px transparent; }
}
