/** Material Baseline */
$mainGreen: #154D25;

/** Fonts */
@font-face {
  font-family: spyitalic;
  src: url(../fonts/spy-italic.ttf);
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*, *::before, *::after {
  box-sizing: inherit;
}

html, body, div#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

h1 {
  color: $mainGreen;
  font-size: 1.4em;
  font-weight: 500;
}

h2 {
  font-size: 2em;
  margin-top: 0em;
}

body {
  display: flex;
  flex-direction: column;

  font-family: 'Roboto', 'Lato', 'Open Sans', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #fff !important;
  color: #000;

  a {
    color: #607d8c;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
}

.main {
  padding: 0; // Changing this breaks signin page.
}

.poster {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.page-container {
  width: 100%;
  text-align: left;
  margin-top: -4.2em;
}

button.custom-btn {
  display: block;
  border: solid 1px $mainGreen;
  width: 100%;
  color: $mainGreen;
  background-color: #eee;
}

a.custom-link {
  margin: 2em 0;
  display: block;
  text-decoration: underline;
  font-weight: 500;
}

.jodit-ui-group_group_source, .jodit-ui-group_group_script, .jodit-ui-group_group_list, .jodit-ui-group_group_indent, .jodit-ui-group_group_font, .jodit-ui-group_group_color, .jodit-ui-group_group_media, .jodit-ui-group_group_clipboard, .jodit-ui-group_group_insert, .jodit-ui-group_group_history, .jodit-ui-group_group_search, .jodit-ui-group_group_other, .jodit-ui-group_group_info {
  display: none;
}

header.MuiPaper-root.MuiAppBar-colorPrimary {
  background: transparent;
}

.side-menu {
  a {
    color: white;
  }
}

.Organization {
  padding: 0 2rem;
}