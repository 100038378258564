.search__container {
  display: flex;
  margin-bottom: 1.5vmin;
}

.search__underline {
  border: 1px solid #004D26;
  border-radius: 30px;
  max-width: 50vmax;
  &:before {
    border-bottom: none !important;
  }
  &:after {
    border-bottom: none !important;
  }
}

.search__button {
  cursor: pointer;
  &:hover {
    color: #004D26;
  }
}