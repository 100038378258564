.add-btn {
    float: right;
}

.card-wrapper-new-location-form {
    padding: 1.2em;

    .cancel-btn {
        margin-left: 1em;
    }

    .new-card-title {
        color: #004D26;
        font-size: 1.3em;
        margin-bottom: 0.5em;
        font-weight: 500;
    }

    .MuiFormControl-marginNormal {
        width: 100%;
    }
}

.btn-clean {
    background: transparent;
    border: 0;
}

.add-btn-container {
    float: right;
    margin-top: 0;
}

.locations-text {
    font-size: 0.7rem;
    font-weight: 600;
    padding-top: 0.55rem;
    margin-right: 0.5rem;
}