$green: #004d26;
$orange: #f65812;

.card-wrapper {
    width: 100%;
    border: solid 1px $green !important;
    border-radius: 1em !important;
    margin-bottom: 0.5em;
    padding-right: 0.5em;

    &:hover {
        box-shadow: 7px 6px 13px 1px #bfbcbc;
    }
}

.MuiCardContent-root.card-content {
    padding: 0;
    padding-bottom: 0 !important;
}

.img-logo {
    width: 100%;
    padding-right: 1em;
    max-width: 13em;
}

.content-text-column {
    padding-left: 1em;

    @media only screen and (max-width: 960px) {
        padding-left: 1em;
    }
}

.column-text {
    font-size: 0.9rem;
    margin: 0.1em 0;
    color: $green;

    p {
        font-weight: 500;
    }

    @media only screen and (max-width: 960px) {
        margin: 0.5em 1em;
    }
}

.text-filled {
    opacity: 0;
}

.icon-text {
    display: inline-flex;
    font-size: 0.8rem !important;
    color: $orange;
    font-weight: bold !important;
    margin-right: 0.3rem !important;
}

.info-text {
    font-size: 0.9rem;
    font-weight: 500;
}

.content-text-column {
    margin-bottom: 2.5rem !important;
}

.cto-content {
    margin-bottom: 0.3rem !important;
    cursor: pointer;
}

button {
    &.green {
        text-indent: -9999px;
    }

    &.icon-arrow {
        color: #fff;
        width: 1.2em !important;
        min-width: 1.5em;
        background-color: $orange;
        padding: 0;
        border-radius: 50%;
        text-align: center;
        padding-right: 0.7rem;

        &:hover {
            background-color: $orange;
        }
    }

    @media only screen and (max-width: 1050px) {
        padding: 0.5em 0;

        &.orange {
            font-size: 0.7rem;
            margin-top: 1.5em !important;
        }

        &.green {
            text-indent: -9999px;
        }
    }
}
