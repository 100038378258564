  .footer {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    flex-shrink: 0;
    margin-top: auto;
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    background-color: #1e88e5;
    color: #fff;
    overflow: hidden;
    margin-top: 5rem;

    & > * {
      padding:0;
      margin: 0;
    }
  }
